import React from 'react'
import Styles from './TokenDistribution.module.scss'
import Icon1 from '../Assets/feature-image-1.png'
import Icon2 from '../Assets/feature-image-2.png'
import Icon3 from '../Assets/feature-image-3.png'
import Icon4 from '../Assets/feature-image-4.png'
import Icon5 from '../Assets/feature-image-5.png'
import Icon6 from '../Assets/feature-image-6.png'
import Icon7 from '../Assets/feature-image-7.png'

const gridData = [
    { id: 1, icon: Icon1, title: 'MULTI-BLOCKCHAIN SUPPORT', description: 'Built on Ethereum and Polygon networks for enhanced versatility and security.' },
    { id: 2, icon: Icon2, title: 'STAKING REWARDS', description: 'Earn rewards by holding and locking WSNPT tokens.' },
    { id: 3, icon: Icon3, title: 'IN-GAME PURCHASES', description: 'Use WSNPT to buy utilities and enhance your Snapit World gaming experience.' },
    { id: 4, icon: Icon4, title: 'LOW TRANSACTION FEES', description: 'Benefit from low fees, especially on the Polygon network.' },
    { id: 5, icon: Icon5, title: 'COMMUNITY-DRIVEN', description: 'Significant token allocation for community incentives.' },
    { id: 6, icon: Icon6, title: 'SECURE AND RELIABLE', description: 'Robust security features ensure safe transactions.' },
    { id: 7, icon: Icon7, title: 'FUTURE-READY', description: 'Significant token allocation for community incentives.' },
];

const TokenDistribution = () => {
    return (
        <div className={Styles.tokenDistribution}>
            <div className={Styles.keyFeature}>
                <div className={Styles.head}>
                    <h4>KEY FEATURES OF WSNPT TOKEN</h4>
                </div>
                <div className={Styles.body}>
                    <div className="container">
                        <div className="row g-4">
                            {gridData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className={Styles.featureCard}>
                                        <div className="row">
                                            <div className="col-2">
                                                <img src={item.icon} alt={item.title} />
                                            </div>
                                            <div className="col-10">
                                                <div className={Styles.content}>
                                                    <h5>{item.title}</h5>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenDistribution