import React, { useEffect, useState } from "react";
import "./form.scss";
import usdtIcon from "../Assets/tether.png"; // Uncomment and set the correct path
import wsnpIcon from "../Assets/wstkn.png"; // Uncomment and set the correct path
import { useChainModal, openConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useBalance, useChainId } from "wagmi";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config/config";
import Web3 from "web3";
import abi from "../../../abi/Token.json";
import {
  buyToken,
  staketoken,
  getStakeduration,
} from "../../../config/Authapi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useReadContract } from "wagmi";
import { red } from "@mui/material/colors";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

const Buystakeform = () => {
  const [activeTab, setActiveTab] = useState("BUY");
  const [currentnetwork, setSelectedNetwork] = useState();
  const [usdtValue, setusdtValue] = useState();
  const [loading, setLoading] = useState();
  const [wsnptValue, setwsnptValue] = useState(0);
  const [conversionRate, setconversionRate] = useState(0.2);
  const [usdtContract, setUsdtcontract] = useState(null);
  const [snapitcontract, setsnapitcontract] = useState(null);
  const [progressWidth, setProgressWidth] = useState(0); // State for controlling progress bar width
  const [showImage, setShowImage] = useState(false); // State for controlling image animation
  const [inputValue, setinputValue] = useState("");
  const [supplypercentage,setsupplypercentage] = useState("")
console.log('supplypercentage', supplypercentage)
  const { openChainModal } = useChainModal();
  const { address, chainId, connector } = useAccount();

  const { data: usdtbalance } = useReadContract({
    address: usdtContract,
    abi: abi, // Provide the correct ABI here
    functionName: "balanceOf",
    args: [address],
  });
  const { data: adminWsnptbalance } = useReadContract({
    address: snapitcontract,
    abi: abi, // Provide the correct ABI here
    functionName: "balanceOf",
    args: [config.Adminaddress],
  });
  const { data: wsnptbalance } = useReadContract({
    address: snapitcontract,
    abi: abi, // Provide the correct ABI here
    functionName: "balanceOf",
    args: [address],
  });
  console.log("wsnptbalance", wsnptbalance);
  useEffect(() => {
    console.log("chain === 1");
    if (chainId === 1) {
      setSelectedNetwork("ETH");
      setUsdtcontract(config.EthUsdtcontract);
      setsnapitcontract(config.ethwrappedsnapit);
    } else if (chainId === 137) {
      setSelectedNetwork("POLYGON");
      setUsdtcontract(config.polygonUsdtContract);
      setsnapitcontract(config.Polygonwrappedsnapit);
    } else {
      setSelectedNetwork("?");
    }
  }, [address, chainId]);

  const confirmorder = async () => {
    console.log("currentchain", currentnetwork);
    if (chainId !== 1) {
      if (chainId !== 137) {
        toast.info("Continue with POLYGON MAINNET OR ETHEREUM ");
        return;
      }
    }

    console.log("usdtValue", usdtValue);
    // if (usdtValue === "" || usdtValue < 100 || usdtValue > 1000000) {
    //   toast.error("Enter a valid USDT Value (between 100 and 1,000,000)");
    //   return;
    // }
    if (!address) {
      toast.info("Connect Your Wallet ");
      return;
    }

    let rpcurl;
    let usdtAddress;
    let wsnptAddress;
    if (chainId === config.Eth_chain_ID) {
      rpcurl = config.ETH_RPC_URL;
      usdtAddress = config.EthUsdtcontract;
      wsnptAddress = config.ethwrappedsnapit;
    } else if (chainId === config.Polygon_CHAIN_ID) {
      usdtAddress = config.polygonUsdtContract;
      rpcurl = config.POLYGON_RPC_URL;
      wsnptAddress = config.Polygonwrappedsnapit;
    }

    // let rpcurl = config.ETH_RPC_URL;
    // let usdtAddress = config.EthUsdtcontract;
    // let wsnptAddress = config.wsnptAddress;
    let web3;
    const provider = await connector.getProvider();

    web3 = new Web3(
      provider || window.ethereum || new Web3.providers.HttpProvider(rpcurl)
    );
    console.log("usdtValue", usdtValue, typeof usdtValue, isNaN(usdtValue));

    if (isNaN(usdtValue) || Number(usdtValue) <= 0) {
      toast.info("Please Enter a Valid Positive Amount");
      return;
    }
    const adminbalance = await web3.eth.getBalance(config.Adminaddress);
    console.log("adminbalance", adminbalance);
    const adbal = web3.utils.fromWei(adminbalance, "ether");
    console.log("adbal", adbal);
    if (adbal < 0.00063) {
      toast.info("Please Try Some Other Time");
      return;
    }
    console.log("usdtAddress", usdtAddress);
    const usdtcontract = new web3.eth.Contract(abi, usdtAddress);

    const decimal = await usdtcontract.methods.decimals().call(); // Get decimals of the token
    const decimals = Number(decimal);

    if (Number(usdtbalance) < usdtValue * 10 ** decimals) {
      toast.error("You don't have enough balance");
      return;
    }
    console.log("decimals", decimals);

    try {
      const gasPrice = await web3.eth.getGasPrice();
      console.log("Current Gas Price: ", gasPrice);
      // return
      setLoading(true); // Set loading state to true
      const adjustedAmount = usdtValue * 10 ** decimals; // Adjust amount based on decimals
      await usdtcontract.methods
        .transfer(config.Adminaddress, adjustedAmount.toString())
        .send({
          from: address,
          gasPrice: gasPrice, // Use dynamically fetched gas price
          gas: 100000,
        })
        .then(async () => {
          let payload = {
            userAddress: address,
            usdtvalue: usdtValue,
            wsnptvalue: wsnptValue,
            wsnptPrice: conversionRate,
            rpcurl: rpcurl,
            contract: wsnptAddress,
            network: currentnetwork,
          };
          const backendcall = await buyToken(payload);
          if (backendcall.status === true) {
            toast.success("Purchase successful");
            setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 4000); // Reload the page after 2 seconds
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((err) => {
          toast.error("Purchase failed");
          console.log(err);
        });
    } catch (error) {
      console.error("Error while transferring USDT:", error);
      toast.error("Error while transferring USDT");
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };
  const [inputerror, setinputerror] = useState("");
  const [stakeinputerror, settakeinputerror] = useState("");

  const confirmStake = async () => {
    if (chainId !== config.Eth_chain_ID) {
      if (chainId !== config.Polygon_CHAIN_ID) {
        toast.info("Continue with POLYGON MAINNET OR ETHEREUM ");
        return;
      }
    }
    if (inputValue === 0 || inputValue < 0) {
      toast.error("Enter WSNPT Value");
      return;
    }
    if (inputValue <500 ) {
      toast.error("Minimum Stake is 500 Wsnpt");
      return;
    }
    if (!address) {
      toast.info("Connect Your Wallet ");
      return;
    }
    let rpcurl;

    let wsnptAddress;
    if (chainId === config.Eth_chain_ID) {
      rpcurl = config.ETH_RPC_URL;

      wsnptAddress = config.ethwrappedsnapit;
    } else if (chainId === config.Polygon_CHAIN_ID) {
      rpcurl = config.POLYGON_RPC_URL;
      wsnptAddress = config.Polygonwrappedsnapit;
    }

    let web3;
    const provider = await connector.getProvider();

    web3 = new Web3(
      provider || window.ethereum || new Web3.providers.HttpProvider(rpcurl)
    );

    const usdtcontract = new web3.eth.Contract(abi, wsnptAddress);

    const decimal = await usdtcontract.methods.decimals().call(); // Get decimals of the token
    const decimals = Number(decimal);

    if (Number(wsnptbalance) < inputValue * 10 ** decimals) {
      toast.error("You don't have enough balance");
      return;
    }
    console.log("decimals", decimals);
    console.log("inputValue,typeof inputvalue", inputValue, typeof inputValue);

    try {
      setLoading(true); // Set loading state to true
      const adjustedAmount = web3.utils.toWei(inputValue, "ether");
      console.log("adjustedAmount", adjustedAmount);
      const gasPrice = await web3.eth.getGasPrice();

      await usdtcontract.methods
        .transfer(config.Adminaddress, adjustedAmount.toString())
        .send({
          from: address,
          gasPrice: gasPrice,
          gas: 100000, // Adjust the gas limit according to the transaction complexity
        });

      // If transferToAdmin resolves without throwing an error, it means the transaction was successful
      // Proceed with the backend call only if the transfer was successful
      let payload = {
        userAddress: address,
        amount: Number(inputValue),
        network: currentnetwork,
      };

      const backendCall = await staketoken(payload);

      if (backendCall.status === true) {
        toast.success("Staking successful");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 4000); // Reload the page after 4 seconds
      } else {
        toast.error("Something went wrong with the backend call");
      }
    } catch (error) {
      console.error("Error while stake:", error);
      toast.error("Error while stake");
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };
  const handleUsdtInputChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      if (value === "" || value === 0) {
        setinputerror(""); // Clear any previous error message if the value is valid
        setusdtValue(value);
        setwsnptValue(value / conversionRate);
      } else if (value < 1) {
        setinputerror("Minimum spend is 100 USDT");
        setusdtValue(value); // Reset the value to 0 if it doesn't meet the minimum requirement
        setwsnptValue(value / conversionRate); // Reset the corresponding WSNPT value as well
      } else {
        setinputerror(""); // Clear any previous error message if the value is valid
        setusdtValue(value);
        setwsnptValue(value / conversionRate);
      }
    } else {
      setinputerror("Please enter a valid number"); // Provide error message for invalid input
      setusdtValue(""); // Reset the value to 0 if it's not a valid number
      setwsnptValue(""); // Reset the corresponding WSNPT value as well
    }
  };
  const handlestake = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      if (value < 500) {
        settakeinputerror("Minimum Staking is 500 WSNPT");
        setinputValue(value);
      } else {
        settakeinputerror(""); // Clear any previous error message if the value is valid
        setinputValue(value);
      }
    } else {
      settakeinputerror("Please enter a valid number"); // Provide error message for invalid input
      setinputValue(value);
    }
  };
  useEffect(() => {
    // Animate progress bar from 0% to 80%
    let interval;
    const animateProgressBar = () => {
      let progress = 0;
      interval = setInterval(() => {
        progress += 1;
        if (progress > supplypercentage) {
          clearInterval(interval);
          setShowImage(true); // Show the image after progress reaches 80%
        }
        setProgressWidth(progress);
      }, 50); // Adjust the interval for smoother or faster animation
    };

    animateProgressBar();

    // Cleanup function
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const progress = async () => {
      try {
        const totalsupply = 100000000; // total supply
        let adminbalance = 0;
        const web3 = new Web3(
          window.ethereum || new Web3.providers.HttpProvider(config.ETH_RPC_URL)
         );
        if (!address) {
          // const provider = await connector.getProvider();
        

          const snapitcontract = new web3.eth.Contract(abi, config.ethwrappedsnapit);
          const adminWsnptbal = await snapitcontract.methods.balanceOf(config.Adminaddress).call();

          console.log('adminWsnptbalance', adminWsnptbal);
          adminbalance = web3.utils.fromWei(adminWsnptbal, 'ether'); // Convert balance from Wei if necessary
        }else{
          adminbalance =  web3.utils.fromWei(adminWsnptbalance, 'ether');
        }

        console.log('adminbalance', adminbalance);

        const calculatePercentage = (balance, total) => {
          if (total === 0) {
            return 0;
          }
          return (balance / total) * 100;
        };

        const percentage = calculatePercentage(adminbalance, totalsupply);
        console.log('percentage', percentage);
        setsupplypercentage(100-percentage);
      } catch (error) {
        console.error('Error in progress function:', error);
      }
    };

    progress();
  }, [address, connector]);

  return (
    <div className="containerform input-buy">
      <ToastContainer />

      <div className="headerform">
        <button
          className={activeTab === "BUY" ? "active" : ""}
          onClick={() => setActiveTab("BUY")}
        >
          BUY
        </button>
        <button
          className={activeTab === "STAKE" ? "active" : ""}
          onClick={() => setActiveTab("STAKE")}
        >
          STAKE
        </button>
      </div>

      {activeTab === "BUY" && (
        <div className="main-content">
          <div className="info">
            <span>Pay Minimum: 100 USDT</span>
          </div>
          {/* <div className="info2">
              <span className='mt-2'>1 WSNPT</span>
              <span className="highlight mt-2"> = 0.2 USDT</span>
            </div> */}
          <div class="login-signup__line-wrap">
            <div class="login-signup__line"></div>
            <span class="login-signup__or">1 WSNPT </span>
            <div class="login-signup__line"></div>
          </div>
          <div className="info2">
            <span className="highlight "> = 0.2 USDT</span>
          </div>

          <div className="info3">
            <span className="info-item">
              Minimum received:{" "}
              <span style={{ color: "#00cfff", fontSize: "11px" }}>
                12.6 WSNPT
              </span>
            </span>
            <span className="info-item-divider d-lg-block d-none"></span>
            <span className="info-item">
              Price Impact:{" "}
              <span style={{ color: "#00cfff", fontSize: "11px" }}>0.01%</span>
            </span>
            <span className="info-item-divider d-lg-block d-none"></span>
            <span className="info-item fee">
              Trading Fee:{" "}
              <span style={{ color: "#00cfff", fontSize: "11px" }}>
                {" "}
                0.00005 {chainId === 137?"MATIC":"ETH"}
              </span>
            </span>
          </div>

          <div className="d-flex align-items-center text-white mt-2 mb-2 ">
            <span className="datastyle-wsnpt" style={{ color: "GrayText" }}>
             {adminWsnptbalance?100000000-(Number(adminWsnptbalance)/1e18):"0"} WSNPT
            </span>
            <span
              className="ms-auto datastyle-wsnpt"
              style={{ color: "GrayText" }}
            >
              100,000,000 WSNPT
            </span>
          </div>
          <div className="info-progree-cont">
            <div
              className="progress position-relative"
              role="progressbar"
              aria-label="Default striped example"
              aria-valuenow={progressWidth}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{height:"1.0rem"}}
            >
              <div
                className="progress-bar progress-bar-striped"
                style={{ width: `${progressWidth}%` }}
              ></div>
              <img
                src={wsnpIcon}
                className="new-wsnp-icon"
                alt="wsnpIcon"
                style={{
                  position: "absolute",
                  left: `calc(${progressWidth}% )`, // Adjust the offset for proper alignment
                  top: "-2px",
                  
                }}
              />
            </div>

            <div className="text-center">
              <div
                className="d-flex align-items-center text-white mb-0"
                style={{ height: "30px" }}
              >
                <span className="datastyle-wsnpt" style={{ color: "#e342dc" }}>
                  {adminWsnptbalance?(100000000-(Number(adminWsnptbalance)/1e18))*0.2:"0"} USDT
                </span>
                <span className="ms-auto datastyle-wsnpt">20000000 USDT</span>
              </div>
            </div>
          </div>

          <div className="balance-sec">
            <div className="balheading ">Balance:</div>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <span className="respHeading ms-1">
                            <span onClick={openConnectModal} type="button">
                              Connect Your Wallet
                            </span>
                          </span>
                        );
                      }
                      return (
                        <span className="respHeading ms-1 ">
                          {Number(usdtbalance) && Number(usdtbalance) > 0
                            ? ` ${Number(usdtbalance) / 1e6} USDT `
                            : "0 USDT"}
                        </span>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>

          <div className="input-group" style={{ color: "white" }}>
            <label onClick={openChainModal} type="button">
              Current Network:{" "}
              <span style={{ color: "#FAFA33" }}>
                {currentnetwork} <IoIosArrowDown />
              </span>
            </label>
          </div>

          <div className="token-select row">
            <div className="col-6">
              <div className="tknsec-1">
                <div className="tknsec">
                  <img src={usdtIcon} alt="WSNPT" /> <span>USDT</span>
                  <br />
                  <input
                    type="number"
                    placeholder="0"
                    value={usdtValue > 0 ? usdtValue : ""}
                    onChange={handleUsdtInputChange}
                    className="input-buy w-100"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="tknsec-1">
                <div className="tknsec">
                  <img src={wsnpIcon} alt="USDT" />{" "}
                  {/* Add usdtIcon variable if needed */}
                  <span>WSNPT</span>
                  <input className="input-buy w-100"
                   type="number"
                    placeholder="0" 
                    value={wsnptValue} disabled />
                </div>
              </div>
            </div>
            <p className="colorfor-p">{inputerror}</p>
          </div>

          <ConnectButton.Custom>
            {({
              account,
              chain,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              const ready = mounted && authenticationStatus !== "loading";
              const connected =
                ready &&
                account &&
                chain &&
                (!authenticationStatus ||
                  authenticationStatus === "authenticated");

              return (
                <div
                  {...(!ready && {
                    "aria-hidden": true,
                    style: {
                      opacity: 0,
                      pointerEvents: "none",
                      userSelect: "none",
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <div className="connect-wallet mt-2">
                          <button onClick={openConnectModal} type="button">
                            Connect Wallet
                          </button>
                        </div>
                      );
                    }
                    if (chain.unsupported) {
                      return (
                        <div className="connect-wallet mt-2">
                          <button onClick={openChainModal} type="button">
                            Wrong network
                          </button>
                        </div>
                      );
                    }
                    if (connected) {
                      return (
                        <div className="connect-wallet mt-2">
                          <button
                            onClick={confirmorder}
                            type="button"
                            disabled={loading}
                          >
                            {loading ? (
                              <span className="processing-text">
                                Processing
                              </span>
                            ) : (
                              "BUY TOKEN"
                            )}
                          </button>
                        </div>
                      );
                    }
                  })()}
                </div>
              );
            }}
          </ConnectButton.Custom>
          {/* <div className="terms">
              <p>
                By pressing I agree, that I have read in full and accept the Terms
                and Conditions and Accompanying Documents in theirs entirety. I'm 18
                years old and not a resident of Prohibited Jurisdictions.
              </p>
            </div> */}
        </div>
      )}

      {activeTab === "STAKE" && (
        <div className="main-content">
          <div className="input-group" style={{ color: "white" }}>
            <label onClick={openChainModal} type="button">
              Current Network:{" "}
              <span style={{ color: "#FAFA33" }}>
                {currentnetwork} <IoIosArrowDown />
              </span>
            </label>
          </div>
          <label className="form-label">Amount to stake</label>
          <div className="input-group input-buy">
            <input
              type="number"
              placeholder="Minimum Stake is 500..."
              value={inputValue > 0 ? inputValue : ""}
              onChange={handlestake}
              className="input-buy"
            />
            <span>
              <img src={wsnpIcon} alt="USDT" />
            </span>
          </div>
          <div className="bal-sec">
            Balance:{" "}
            {Number(wsnptbalance) / 1e18 > 0
              ? Number(wsnptbalance) / 1e18
              : "0"}{" "}
            WSNPT
          </div>

          <div className="stk-btn">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <div className="connect-wallet mt-2">
                            <button onClick={openConnectModal} type="button">
                              Connect Wallet
                            </button>
                          </div>
                        );
                      }
                      if (chain.unsupported) {
                        return (
                          <div className="connect-wallet mt-2">
                            <button onClick={openChainModal} type="button">
                              Wrong network
                            </button>
                          </div>
                        );
                      }
                      if (connected) {
                        return (
                          <div className="connect-wallet mt-2">
                            <button
                              onClick={confirmStake}
                              type="button"
                              disabled={loading}
                            >
                              {loading ? (
                                <span className="processing-text">
                                  Processing
                                </span>
                              ) : (
                                "CONFIRM STAKE"
                              )}
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>

          <div className="apy-details">
            <div className="detail-btn">
              Total staked WSNPT
              <span className="cal-detail">67,874 WSNPT = $32.65m</span>
            </div>
            <div className="detail-btn">
              APR<span className="cal-detail">30%</span>
            </div>
            <div className="detail-btn">
              Locked up period<span className="cal-detail">365 days</span>
            </div>
          </div>
        </div>
      )}
      <div className="transaction-history-1">
                            <div className="transaction-history">
                                <a href={activeTab === "BUY"?"/Buyhistory":"/StakeHistory"}>History Of Your Transactions</a>
                            </div>
                        </div>
    </div>
    
  );
};

export default Buystakeform;
