import React from 'react';
import styles from './snapland.module.scss';
import tokenImage from '../Assets/snapitland.png'; // Adjust the path as per your folder structure

const SnapitLand = () => {
  return (
    <section className={styles.tokenSection}>
      <div className={styles.container}>

        <div className='row'>
          <div className='col-lg-6  col-md-12'>
            <div className={styles.tknimg}>
              <img src={tokenImage} alt="SNAPIT land" className={`${styles.tokenImage} img-fluid`} />
            </div>

          </div>
          <div className='col-lg-6 col-md-12'>
            <div className={styles.tokenContent}>
              <h2 className={styles.tokenTitle}>SNAPIT LAND</h2>
              <p className={styles.tokenText}>
              Snapit Land will be a vast, vibrant digital landscape where creativity and community will truly thrive. This dynamic virtual environment will allow users to build, explore, and interact in countless exciting ways. Stay tuned with us for more thrilling updates about Snapit Land, as we prepare to unveil a world full of endless possibilities and boundless innovation. The future of digital exploration and community building is coming, and it's going to be absolutely extraordinary!

              </p>
              <button className={styles.tokenButton}>KNOW MORE</button>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default SnapitLand;
