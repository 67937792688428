import React from 'react'
import Styles from './TokenAbout.module.scss'

const TokenAbout = () => {
    return (
        <div className={Styles.tokenAbout}>
            <div className='container fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <h4>ABOUT WSNPT</h4>
                        <p>
                            The WSNPT token is an essential part of the Snapit World metaverse ecosystem. This versatile digital asset is<br/> 
                            built on the Ethereum and Polygon blockchain networks, ensuring robust security and fast transactions. WSNPT<br/> 
                            serves multiple functions within Snapit World, including staking, purchasing in-game utilities, and enhancing the<br/> 
                            overall gaming experience with various assets and benefits.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenAbout