import { makeApiRequest } from "../config/axiosService";

  export const buyToken = async (bodyData) => {
    try {
      let params = {
        url: `buytoken`,
        method: "POST",
        data: bodyData
      }
      let response = await makeApiRequest(params);
      return {
        status:response.status,
        message: response.message
      }
    } catch (error) {
      console.log('buytoken error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }

  export const staketoken = async (bodyData) => {
    try {
      let params = {
        url: `staketoken`,
        method: "POST",
        data: bodyData
      }
      let response = await makeApiRequest(params);
       console.log('response',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getLoanHistory error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }

  export const getStakeduration = async (datad) => {
    try {
      // console.log('datad', datad)
      let params = {
        url: `getstakedata`,
        method: "POST",
        data:datad
      }
      let response = await makeApiRequest(params);
        // console.log('stakeresponse',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getAdminFeeAmount error',error);
      return {
        status:false,
        message: "Error on server"
      }
    } 
  }
  export const getbuydata = async (datad) => {
    try {
      // console.log('datad', datad)
      let params = {
        url: `getbuydata`,
        method: "POST",
        data:datad
      }
      let response = await makeApiRequest(params);
        // console.log('stakeresponse',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getAdminFeeAmount error',error);
      return {
        status:false,
        message: "Error on server"
      }
    } 
  }