import React from 'react';
import styles from './about.module.scss';
import icon1 from '../Assets/icon1.png';
import icon2 from '../Assets/icon2.png';

const About = () => {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.aboutContainer}>
        <img src={icon1} alt="Icon 1" className={styles.iconLeft} />
        <div className={styles.aboutContent}>
          <h2 className={styles.aboutTitle}>ABOUT</h2>
          <p className={styles.aboutText}>
            Step into Snapit World, where imagination meets reality in a boundless metaverse ecosystem. Explore immersive virtual environments, connect with global communities, and unleash your creativity like never before. With innovative features and endless possibilities, Snapit World offers a transformative digital experience for users of all interests. Join us in shaping the future of digital interaction and collaboration within the dynamic realms of Snapit World.
          </p>
        </div>
        <img src={icon2} alt="Icon 2" className={styles.iconRight} />
      </div>
    </section>
  );
};

export default About;
