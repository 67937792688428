import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home/Home";
import "./App.css";
import Game from "./component/Game/Game";
import Layout from "./component/Layout/Layout";
import Token from "./component/Token/Token";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme,
 
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, polygon } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {

  metaMaskWallet,
  trustWallet,
  coinbaseWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import HistoryTable from "./table";
import HistoryTable2 from "./table2";

import ChartComponent from "./component/Token/chart";

function App() {
  const myTheme = {
    ...darkTheme(),
    colors: {
      ...darkTheme().colors,
      accentColor: "#07296d",
    },
    fonts: {
      body: 'inter',
    },
  };
  const connectors = connectorsForWallets(
    [
      {
        groupName: "Available Wallets (4)",
        wallets: [trustWallet, metaMaskWallet,coinbaseWallet,walletConnectWallet],
      },
     
    ],
    { appName: "RainbowKit App", projectId: "1f0b9efb280db82bff5edc4689ffa76a" }
  );

  const config = getDefaultConfig({
    appName: "My RainbowKit App",
    projectId: "1f0b9efb280db82bff5edc4689ffa76a",
    chains: [mainnet, polygon],
    ssr: true,
    connectors,
  });

  const queryClient = new QueryClient();

  return (
    <Router>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={myTheme}>
            <div className="App">
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="/snap-salvage" element={<Game />} />
                  <Route path="/buy-wsnpt" element={<Token />} />
                  <Route path="/Buyhistory"element={<HistoryTable2/>}/>
                  <Route path="/StakeHistory"element={<HistoryTable/>}/>

                  <Route path="/chart"element={<ChartComponent/>}/>
                </Route>
              </Routes>
            </div>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Router>
  );
}

export default App;