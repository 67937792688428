import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Logo from './Assets/SnapitWorld-Logo-4 2.png';
import styles from './Header.module.scss';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const NavbarComponent = () => {
  const [activeTab, setActiveTab] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [menuBtn, setMenuBtn] = useState(false);

  const toggleMenu = () => {
    setMenuBtn(!menuBtn);
  };
  const handleLinkClick = (path) => {
    setActiveTab(path);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: "en,tr,ar,ru,de,fr",
        // pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
    const intervalId = setInterval(() => {
      const selectElement = document.querySelector('.goog-te-combo');
      if (selectElement) {
        // selectElement.querySelector('option[value=""]').style.display = 'none';
        selectElement.value = 'en'; // Set the default selected language to English

        // Trigger change event to update the display
        const event = new Event('change', { bubbles: true });
        selectElement.dispatchEvent(event);

        clearInterval(intervalId);
      }
    },);

    var $googleDiv = $("#google_translate_element .skiptranslate");
    var $googleDivChild = $("#google_translate_element .skiptranslate div");
    $googleDivChild.next().remove();

    $googleDiv
      .contents()
      .filter(function () {
        return this.nodeType === 3 && $.trim(this.nodeValue) !== "";
      })
      .remove();
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);



  return (
    <header className={`${styles.hdr} ${scrolled ? styles.scrolled : ''}`}>
      <div className='container'>
        <div className={`${styles.hdrRow} row`}>
          <div className={`${styles.hdrCol} col`}>
            <div className={styles.hdrfullist}>
              <Link to="/" reloadDocument >
                <img src={Logo} alt="SnapitWorld Logo" className={`${styles.logoimg} img-fluid`} />
              </Link>
              <nav className={`${styles.hdrnavbr} d-lg-block d-none`}>
                <ul>
                  {/* <li className={styles.hdrmenulst}><Link to="/" className={`${styles.menuname} ${activeTab === 'ABOUT' ? styles.activeNavLink : ""}`} onClick={() => handleLinkClick('ABOUT')}>ABOUT</Link></li> */}
                  <li className={styles.hdrmenulst}><Link to="/buy-wsnpt" className={`${styles.menuname} ${activeTab === 'TOKEN' ? styles.activeNavLink : ""}`} onClick={() => handleLinkClick('TOKEN')}>TOKEN</Link></li>
                  <li className={styles.hdrmenulst}><Link to="/snap-salvage" className={`${styles.menuname} ${activeTab === 'GAME' ? styles.activeNavLink : ""}`} onClick={() => handleLinkClick('GAME')}>GAME</Link></li>
                  <li className={styles.hdrmenulst}><Link to="/" className={`${styles.menuname} ${activeTab === 'BLOG' ? styles.activeNavLink : ""}`} onClick={() => handleLinkClick('BLOG')}>BLOG</Link></li>
                  <li className={styles.hdrmenulst}><Link to="/whitepaper.pdf" className={`${styles.menuname} ${activeTab === ''}`} target="_blank" rel="noopener noreferrer">WHITEPAPER</Link></li>
                </ul>
              </nav>
              <div className={`${styles.connectbutton} d-none d-lg-block`}>
                <ConnectButton />
              </div>
              <div className="language d-none d-lg-block" id="google_translate_element" />
              <div
                className={`${styles.hamburger_container} ${menuBtn ? styles.active : styles.inactive}`.trim()}
              >
                <button className={styles.menu_close} onClick={toggleMenu}>
                  Close
                </button>
                <div className={styles.menu_logo}>
                  <img src={Logo} alt="SnapitWorld Logo" className={` img-fluid`} />
                </div>
                <ul>
                  <li><a href="/">HOME</a></li>
                  <li><a href="/snap-salvage">GAME</a></li>
                  <li><a href="/buy-wsnpt">TOKEN</a></li>
                  <li><a href="/">BLOG</a></li>
                  <li><a href="/whitepaper.pdf" target="_blank" rel="noopener noreferrer">WHITEPAPER</a></li>
                </ul>
                <div className={styles.folowUs}>
                  <div className={styles.folwIcnLstMn}>
                    <div className={styles.flwIcnLst}>
                      <ConnectButton />
                    </div>
                  </div>
                </div>
              </div>
              <button type='button' className={`${styles.hdrMuBtnG} d-lg-none d-block`} onClick={toggleMenu}>
                <div className={styles.hdrMenu}>
                  <span></span>
                </div>
              </button>




            </div>

          </div>
        </div>
      </div>

    </header>
  );
};

export default NavbarComponent;


