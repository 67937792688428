import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const ChartComponent = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Apply theme
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create(chartRef.current, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // Add data
    chart.data = [
      {
        category: "Community",
        value: 30,
        color: am4core.color("#FF5733") // Red
      },
      {
        category: "Board Members",
        value: 30,
        color: am4core.color("#33C1FF") // Blue
      },
      {
        category: "Treasury",
        value: 15,
        color: am4core.color("#9C33FF") // Purple
      },
      {
        category: "Metaverse Ecosystem Rewards",
        value: 5,
        color: am4core.color("#FF33A6") // Pink
      },
      {
        category: "Development & Marketing",
        value: 15,
        color: am4core.color("#FFD700") // Yellow
      },
      {
        category: "Liquidity",
        value: 5,
        color: am4core.color("#33FF83") // Green
      }
    ];

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#FFFFFF"); // Set legend label text color to white

    // Create series
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.slices.template.propertyFields.fill = "color";
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.labels.template.fill = am4core.color("#FFFFFF"); // Set chart label text color to white
    series.ticks.template.stroke = am4core.color("#FFFFFF"); // Set tick color to white

    // // Format labels as percentages without decimals
    // series.labels.template.adapter.add("text", function(text) {
      
    //     return text 
      
    // });

    // Cleanup
    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div>
      <div style={{ width: '100%', height: '600px' }} ref={chartRef}></div>
    </div>
  );
};

export default ChartComponent;
