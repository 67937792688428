import React, { Fragment } from 'react';
import HomeBanner from './Banner/Banner';
import About from './About/About';
import Token from './Token/Token';
import Snapsalvage from './Snap/Snapsalvage';
import SnapitLand from './Snapitland/SnapitLand';
import HomeFaq from './Faq/Faq';


const Home = () => {
  return (
    <Fragment>
      <HomeBanner />
      <About />
      <Token />
      <Snapsalvage />
      <SnapitLand />
      <HomeFaq />
    </Fragment>

  );
};


export default Home;
