import React from 'react';
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import styles from './faq.module.scss';

const HomeFaq = () => {
  return (
    <div className='mt-3 font-family: "inter"' >
      <div className={styles.accordionContainer}>
        <h2 className={styles.faqTitle}>Frequently Asked Questions</h2>
        <div className={styles.faqScroll}>
          <UncontrolledAccordion defaultOpen="">
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="0" className={styles.accordionHeader}>
                What is Snapit World?
              </AccordionHeader>
              <AccordionBody accordionId="0" className={styles.accordionBody}>
                Snapit World is an emerging metaverse ecosystem that leverages cutting-edge technologies like Web3, AI, AR, VR, blockchain, NFT, and Crypto to create a vibrant digital universe. It encompasses various experiences such as gaming, a virtual digital landscape, social networking, and more.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="1" className={styles.accordionHeader}>
                What is WSNPT, and how does it relate to Snapit World?
              </AccordionHeader>
              <AccordionBody accordionId="1" className={styles.accordionBody}>
                WSNPT is the native cryptocurrency token of Snapit World. It serves as the primary means of value exchange within the ecosystem, enabling users to participate in various activities, stake for rewards, and engage in decentralized finance (DeFi) operations.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="2" className={styles.accordionHeader}>
                What is Snapit Land, and what can users expect from it?
              </AccordionHeader>
              <AccordionBody accordionId="2" className={styles.accordionBody}>
                Snapit Land is a future-facing component of Snapit World, envisioned as a vast digital landscape fostering creativity and community interaction. Users will have the opportunity to build, explore, and collaborate within this sandbox-like environment, creating unique experiences and virtual spaces.

              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="3" className={styles.accordionHeader}>
                How does Snapit World utilize AI technology?
              </AccordionHeader>
              <AccordionBody accordionId="3" className={styles.accordionBody}>
                Snapit World harnesses AI technology to enhance user experiences across various aspects of the ecosystem. From personalized recommendations and intelligent avatars to AI-driven content creation and moderation, AI plays a pivotal role in optimizing interactions and engagement within the metaverse.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="4" className={styles.accordionHeader}>
                What are the advantages of integrating blockchain technology into Snapit World?
              </AccordionHeader>
              <AccordionBody accordionId="4" className={styles.accordionBody}>
                Blockchain technology ensures transparency, security, and decentralization within Snapit World. It enables immutable record-keeping, facilitates peer-to-peer transactions, empowers users with true ownership of digital assets, and fosters trust through smart contract execution.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="5" className={styles.accordionHeader}>
                What is Snap Salvage, and how does it fit into Snapit World?
              </AccordionHeader>
              <AccordionBody accordionId="5" className={styles.accordionBody}>
                Snap Salvage is an immersive 3D first-person shooter multiplayer game integrated with blockchain technology. Players can experience thrilling gameplay while benefiting from features such as asset ownership, verifiable scarcity through NFTs, and decentralized governance.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="6" className={styles.accordionHeader}>
                Can users earn rewards within Snapit World?

              </AccordionHeader>
              <AccordionBody accordionId="6" className={styles.accordionBody}>
                Yes, users can earn rewards within Snapit World through various mechanisms such as staking WSNPT, participating in Snap Salvage gameplay, engaging in DeFi protocols, and contributing to the development and growth of the ecosystem.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="7" className={styles.accordionHeader}>
                How does Snapit World prioritize security and privacy for its users?
              </AccordionHeader>
              <AccordionBody accordionId="7" className={styles.accordionBody}>
                Snapit World implements robust security measures and privacy protocols to safeguard user data and assets. Encryption techniques, decentralized storage solutions, and permissioned access controls ensure that user information remains protected and confidential.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="8" className={styles.accordionHeader}>
                How does Snapit World leverage NFTs?
              </AccordionHeader>
              <AccordionBody accordionId="8" className={styles.accordionBody}>
                Non-fungible tokens (NFTs) are integral to Snapit World's ecosystem, providing unique digital assets that represent ownership of in-game items, virtual real estate, digital art, and more. NFTs ensure authenticity, scarcity, and provable ownership, enriching user experiences and enabling new forms of value creation.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className={styles.accordionItem}>
              <AccordionHeader targetId="9" className={styles.accordionHeader}>
                What are the future plans for Snapit World, and how can users stay updated?
              </AccordionHeader>
              <AccordionBody accordionId="9" className={styles.accordionBody}>
                Snapit World is continuously evolving and expanding its offerings to provide users with cutting-edge experiences. To stay updated on the latest developments, users can follow official announcements on the Snapit World website, social media channels, and community forums.
              </AccordionBody>
            </AccordionItem>


          </UncontrolledAccordion>
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;
