// src/components/Footer.js
import React from 'react';
import styles from './footer.module.scss';

import fbIcon from './Assets/facebook.png';
import twitterIcon from './Assets/twitter.png';
import linkedinIcon from './Assets/linkedin.png';
import instagramIcon from './Assets/instagram.png';
import telegramIcon from './Assets/telegram.png';
import youtubeIcon from './Assets/youtube.png';
import pinterestIcon from './Assets/pinterest.png';
import discordIcon from './Assets/discord.png';

const Footer = () => {
  return (
    <div className={`${styles.footerContainer} container`}>

      <div className={styles.socialLinks}>
        <div className='row'>

          <div className='col-lg-6'>
            <div className={styles.leftwrapp}>
              <h3 className={styles.leftheading}>Join Our Community</h3>
              <div className={styles.icons}>
      <div className={styles.icon}>
        <a href="https://www.facebook.com/snapitworld" target="_blank" rel="noopener noreferrer">
          <img src={fbIcon} alt="Facebook" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://x.com/Snapitworld" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://www.linkedin.com/in/snapitworld/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://www.instagram.com/snapit.world" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://t.me/snapitworld" target="_blank" rel="noopener noreferrer">
          <img src={telegramIcon} alt="Telegram" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://www.youtube.com/c/snapitworld" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="YouTube" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://www.pinterest.com/snapitworld" target="_blank" rel="noopener noreferrer">
          <img src={pinterestIcon} alt="Pinterest" className='img-fluid' />
        </a>
      </div>
      <div className={styles.icon}>
        <a href="https://discord.com/channels/1201450884777918517/1220277612849659905" target="_blank" rel="noopener noreferrer">
          <img src={discordIcon} alt="Discord" className='img-fluid' />
        </a>
      </div>
    </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className={styles.rigthwrapp}>
            <h3 className={styles.rightheading}>Quick Links</h3>
            <div className={styles.quickLinks}>
              <a href="/snap-salvage">Game</a>
              <a href="/buy-wsnpt">Token</a>
              <a href="/buy-wsnpt">Buy Token</a>
              <a href="/buy-wsnpt">Staking</a>
              <a href="#">Blog</a>
              <a href="/whitepaper.pdf" target='_blank'>Whitepaper</a>
            </div>
            </div>
          </div>
        </div>

      </div>


      <div className={styles.copyright}>
       Copyrights &copy; 2023-2024 Snapitverse Technology Corp. All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
