import React from 'react';
import styles from './stepguide.module.scss';
import signup from '../Assets/signup.png';
import connect from '../Assets/connect.png';

const StepGuide = () => {
    const steps = [
        {
            title: "SIGN UP",
            description: "Create your account on SNAP SALVAGE and embark on your journey into the metaverse. It only takes a few moments to join the adventure.",
            icon: signup,
        },
        {
            title: "CONNECT YOUR WALLET",
            description: "Link your digital wallet to the gaming platform to access the full range of features, including purchasing WSNPT tokens and acquiring game accessories.",
            icon: connect,
        },
        {
            title: "START PLAYING",
            description: "Dive into the heart-pounding action of SNAP SALVAGE and immerse yourself in thrilling missions, intense battles, and epic adventures. The metaverse awaits your command.",
            icon: signup,
        },
        {
            title: "EARN REWARDS",
            description: "Rise through the ranks, complete challenges, and earn valuable rewards as you conquer the metaverse. With every victory, your legend grows, and your legacy is cemented in the annals of gaming history.",
            icon: connect,
        },
    ];

    return (
        <section className={styles.stepGuideSection}>
            <div className={styles.container}>
                <h2 className={styles.title}>STEP-BY-STEP GUIDE TO ENTER SNAP SALVAGE</h2>
                <div className={styles.stepsContainer}>
                    {steps.map((step, index) => (
                        <div
                            className={`${styles.step} ${index % 2 === 0 ? styles.left : styles.right}`}
                            key={index}
                        >
                            <div className={styles.content}>
                                <h3 className={styles.stepTitle}>
                                    {step.title}
                                    <img src={step.icon} alt={`${step.title} icon`} className={styles.iconImage} />
                                </h3>
                                <p className={styles.stepDescription}>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default StepGuide;
