import React, { useEffect, useState } from "react";
import "./table.css";
import { useAccount } from "wagmi";
import { getStakeduration } from "./config/Authapi";
import { addYears, differenceInDays } from "date-fns";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import wallet from "./wallet.jpg"
import { TbWalletOff } from "react-icons/tb";

const HistoryTable = () => {
  const { address } = useAccount();
  const [stakeData, setStakeData] = useState([]);

  const Balancefetch = async () => {
    if (!address) {
      return;
    }
    try {
      const data = await getStakeduration({ userAddress: address });
      setStakeData(data.data);
      console.log("stakeData", data.data);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  useEffect(() => {
    Balancefetch();
  }, [address]);

  const getClaimDate = (stakedTimestamp) => {
    return addYears(new Date(stakedTimestamp), 2);
  };

  const getTimeUntilClaim = (stakedTimestamp) => {
    const claimDate = getClaimDate(stakedTimestamp);
    const now = new Date();
    const daysUntilClaim = differenceInDays(claimDate, now);
    return daysUntilClaim > 0
      ? `${daysUntilClaim} days left`
      : "You can claim now";
  };

  return (
    <div className="container mt-4">
      <div className="custom-history-page-1">
        <div className="row py-4 justify-content-center">
          <div className="col-lg-7">
            <h1
              className="headerforhistory"
              style={{
                color: "white",
                padding: "120px 0px 20px 0px",
                textAlign: "center",
                letterSpacing: "2px",
                fontWeight: "700",
              }}
            >
              Transactions History
            </h1>
            {address ? (
              stakeData && stakeData.length > 0 ? (
                <div className="inside-history">
                  <div className="custom-part-table">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-lg-12">
                        <div className="custom-history-table">
                          <div className="mt-3" >
                            <table className="table border-0">
                              <thead>
                                <tr>
                                  <th scope="col">S.no</th>
                                  <th scope="col">Staked Amount</th>
                                  <th scope="col">Claim Status</th>
                                  <th scope="col">Claim Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {stakeData.map((data, index) => (
                                  <tr key={data._id}>
                                    <td>{index + 1}</td>
                                    <td>{data.stakedAmount}</td>
                                    <td>
                                      {data.claimstatus
                                        ? "Claimed"
                                        : "Not Claimed"}
                                    </td>
                                    <td>
                                      {data.claimstatus
                                        ? "Already claimed"
                                        : getTimeUntilClaim(
                                            data.stakedTimestamp
                                          )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center inside-history">
                 

                  <p className="data-css ">
                  No Data Available</p>
                </div>
              )
            ) : (
              <div
                style={{ height: "400px" }}
                className="d-flex justify-content-center py-5"
              >
                <ConnectButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryTable;
