import React from 'react'
import Styles from './TokenBanner.module.scss'
import Buystakeform from './Buystakeform'


const TokenBanner = () => {
    return (
        <div className={Styles.tokenBanner}>
            <div className="container-fluid">
                <div className="row g-4 align-items-center">
                    <div className={`${Styles.left} col-lg-6`}>
                        <h2>WSNPT: FUELING ADVENTURES<br />IN THE SNAPIT WORLD METAVERSE</h2>
                        <p>
                            Experience the future of digital assets with WSNPT, the
                            native token of the Snapit World metaverse ecosystem.
                            Built on Ethereum and Polygon, WSNPT is a versatile asset
                            that powers the Snapit World, offering staking rewards and
                            in-game utilities.
                        </p>
                        <button className={Styles.bannerBtn}>BUY TOKEN</button>
                    </div>
                    <div className={`${Styles.right} col-lg-6`}>
                        <div className={Styles.formOverlay}>
                            
                            <Buystakeform />

                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenBanner
