import React from "react";
import metaverseimg2 from '../Assets/Gunimg.png';
import Styles from './conqure.module.scss';

const ConqureMetaverse = ()=>{
    return(
         <section className={`${Styles.metaverse} container`}>
                <div className={`${Styles.metaverseSplit} row`}>
                    <div className={`${Styles.content} col-lg-6 col-md-12`}>
                        <h3>CONQUER THE METAVERSE <br /> WITH SNAP  SALVAGE</h3>
                        <p>
                            SNAP SALVAGE thrusts you into a thrilling world of futuristic
                            landscapes, pulse-pounding battles, and endless 
                            excitement. As a skilled salvage operator, you'll explore 
                            diverse virtual environments, scavenge for valuable 
                            resources, and engage in intense shootouts 
                            against formidable foes. With stunning graphics, immersive sound 
                            design, and dynamic gameplay, SNAP SALVAGE sets a new 
                            standard for action-packed gaming in the metaverse.
                        </p>
                    </div>
                    <div className={`${Styles.images} col-lg-6 col-md-12`}>
                        <img src={metaverseimg2} alt="" width="3580px" height="3188px" className={Styles.image} />
                    </div>
                </div>
            </section>
    )
}

export default ConqureMetaverse