import React from 'react';
import styles from './salientGameFeatures.module.scss';

const features = [
  { id: 1, title: 'IMMERSIVE GAMEPLAY', description: 'Dive into a richly detailed metaverse filled with immersive environments, intense battles, and endless possibilities.' },
  { id: 2, title: 'DYNAMIC COMBAT', description: 'Engage in fast-paced shooting action as you confront enemy forces, navigate treacherous terrain, and master a diverse arsenal of weapons.' },
  { id: 3, title: 'EXPLORATION', description: 'Explore vast, open-world environments filled with hidden treasures, secret passageways, and unexpected challenges.' },
  { id: 4, title: 'CUSTOMIZATION', description: 'Personalize your gameplay experience with a wide range of customizable options, including character customization, weapon upgrades, and vehicle enhancements.' },
  { id: 5, title: 'MULTIPLAYER MODE', description: 'Team up with friends or compete against other players in adrenaline-pumping multiplayer battles.' },
  { id: 6, title: 'PROGRESSION SYSTEM', description: 'Earn experience points, level up your character, and unlock new abilities and rewards as you progress through the game.' },
];

const SalientGameFeatures = () => {
  return (
    <section className={styles.featuresSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>Salient Game Features</h2>
        <div className="row">
          {features.map((feature) => (
            <div key={feature.id} className={`col-lg-4 col-md-6 col-sm-12 ${styles.featureItem}`}>
              <div className={styles.featureBox}>
                <h3 className={styles.featureNumber}>{`0${feature.id}`}</h3>
                <h4 className={styles.featureTitle}>{feature.title}</h4>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SalientGameFeatures;
