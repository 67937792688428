import React from 'react';
import styles from './banner.module.scss';
import BnrImg from "../Assets/Rectangle.png";
import Marquee from "react-fast-marquee";
import scroll1 from '../Assets/binance smartchain.png'
import scroll2 from '../Assets/ETH_logo.png'
import scroll3 from '../Assets/Polygon.png'
import scroll4 from '../Assets/unity.png'
import scroll5 from '../Assets/meta.png'

const HomeBanner = () => {
  return (
    <section className={styles.mainContent}>
    
        <div className={styles.imagSec}>

        <img src={BnrImg} alt="Main content" className={`${styles.fullImage} img-fluid`} width={1920} height={920} />
        </div>
  
      
      <div className={`${styles.scroll} `}>
        <Marquee>
          <img src={scroll1} alt='' />
          <img src={scroll2} alt='' />
          <img src={scroll3} alt='' />
          <img src={scroll4} alt='' />
          <img src={scroll5} alt='' />
          <img src={scroll1} alt='' />
          <img src={scroll2} alt='' />
          <img src={scroll3} alt='' />
          <img src={scroll4} alt='' />
          <img src={scroll5} alt='' />
        </Marquee>
      </div>
    </section>
  );
};


export default HomeBanner;
