import React, { Fragment } from 'react'
import TokenBanner from './TokenBanner/TokenBanner'
import TokenAbout from './TokenAbout/TokenAbout'
import TokenDistribution from './TokenDistribution/TokenDistribution'
import TokenBuy from './TokenBuy/TokenBuy'
import TokenFaq from './TokenFaq/TokenFaq'
import TokenDesclimer from './TokenDesclimer/TokenDesclimer'
import Group from './Assets/Group 1.png'
import HomeFaq from '../Home/Faq/Faq'
import ChartComponent from './chart'


const Token = () => {
  return (
    <Fragment>
      <TokenBanner />
      <TokenAbout />
      <TokenDistribution />
      <div className='container-fluid py-5'>
        <div className='row justify-content-center'>
          <div className='col-lg-10'>
            {/* <img src={Group} alt='Group' className='img-fluid' /> */}
            <ChartComponent/>
          </div>
        </div>
      </div>
      <TokenBuy />
      {/* <TokenFaq /> */}
      <HomeFaq />
      <TokenDesclimer />
    </Fragment>
  )
}

export default Token