import React, { Fragment } from 'react'
import Styles from './metaverse.module.scss'
import metaverseImg1 from '../Assets/metaverse-1.png';

const Metaverse = () => {
    return (
        <Fragment>
            <section className={`${Styles.metaverse} container`}>
                <div className={`${Styles.metaverseSplit} row`}>
                    <div className={`${Styles.images} col-lg-6 col-md-12`}>
                        <img src={metaverseImg1} alt="" width="3580px" height="3188px" className={Styles.image} />
                    </div>
                    <div className={`${Styles.content} col-lg-6 col-md-12`}>
                        <h3>UNLEASH YOUR POTENTIAL <br /> IN THE METAVERSE</h3>
                        <p>
                            In SNAP SALVAGE, players are immersed in an epic metaverse
                            shooting game that challenges their skills, strategy, and reflexes.
                            Set in a futuristic world where chaos reigns, players embark on
                            adrenaline-fueled missions to salvage valuable resources from the
                            wreckage of war-torn landscapes. With stunning visuals, dynamic
                            gameplay, and a vast universe to explore, SNAP SALVAGE promises an
                            unparalleled gaming experience for enthusiasts of all levels.
                        </p>
                    </div>
                </div>
            </section>
           
        </Fragment>
    )
}

export default Metaverse