import React from 'react'
import Styles from './TokenBuy.module.scss'
import TokenBuy1 from '../Assets/token-buy-image-1.png'
import TokenBuy2 from '../Assets/token-buy-image-2.png'
import TokenBuy3 from '../Assets/token-buy-image-3.png'
import TokenBuy4 from '../Assets/token-buy-image-4.png'

const TokenBuy = () => {
    return (
        <div className={Styles.tokenBuy}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className={Styles.howToken}>
                            <div className={Styles.head}>
                                <h4>HOW TO BUY WSNPT TOKENS</h4>
                            </div>
                            <div className={Styles.body}>
                                <div className={Styles.howTokenCard}>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className={Styles.image}>
                                                <img src={TokenBuy1} alt='' />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className={Styles.content}>
                                                <h5>CONNECT YOUR CRYPTO WALLET</h5>
                                                <p>
                                                    Ensure you have a crypto wallet compatible<br />
                                                    with Ethereum or Polygon networks.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.howTokenCard}>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className={Styles.image}>
                                                <img src={TokenBuy2} alt='' />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className={Styles.content}>
                                                <h5>CONNECT YOUR CRYPTO WALLET</h5>
                                                <p>Select either the Ethereum or Polygon blockchains.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.howTokenCard}>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className={Styles.image}>
                                                <img src={TokenBuy3} alt='' />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className={Styles.content}>
                                                <h5>CONNECT YOUR CRYPTO WALLET</h5>
                                                <p>
                                                    Use USDT (Tether) to purchase WSNPT<br />
                                                    tokens. The minimum amount required is 100<br />
                                                    USDT.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={Styles.rightImg}>
                            <img src={TokenBuy4} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenBuy