import React, { Fragment } from 'react'
import Styles from './clantkn.module.scss'
import metaverseImg1 from '../Assets/clanImg.png';
import metaverseimg2 from '../Assets/unlock.png';

const ClanTkn = () => {
    return (
        <Fragment>
            <section className={`${Styles.metaverse} container`}>
                <div className={`${Styles.metaverseSplit} row`}>
                    <div className={`${Styles.images} col-lg-6 col-md-12`}>
                        <img src={metaverseImg1} alt="" width="3988px" height="2108px" className={Styles.image} />
                    </div>
                    <div className={`${Styles.content} col-lg-6 col-md-12`}>
                        <h3>JOIN A CLAN</h3>
                        <p>
                        In SNAP SALVAGE, teamwork reigns supreme. Join forces with fellow players by forming or joining a clan, and strategize your way to victory. Coordinate attacks, share resources, and establish your dominance in the metaverse. With strength in numbers, nothing can stand in your way.
                        </p>
                    </div>
                </div>
            </section>
            <section className={`${Styles.metaverse} container`}>
                <div className={`${Styles.metaverseSplit} row`}>
                    <div className={`${Styles.content} col-lg-6 col-md-12`}>
                        <h3>UNLOCK YOUR ARSENAL <br /> WITH WSNPT TOKENS</h3>
                        <p>
                        SNAP SALVAGE introduces the native WSNPT crypto token, which serves as the primary currency within the game ecosystem. Players can acquire WSNPT tokens through gameplay achievements or by purchasing them directly on the gaming platform. These tokens can then be used to purchase various in-game accessories, enhancing your gameplay experience and giving you an edge in battle.
                        </p>
                    </div>
                    <div className={`${Styles.images1} col-lg-6 col-md-12`}>
                        <img src={metaverseimg2} alt=""  className={`${Styles.image1} img-fluid`} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ClanTkn