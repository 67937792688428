import React, { Fragment } from 'react'
import Styles from './game.module.scss'
import GameBanner from './GameBanner/GameBanner'
import Metaverse from './Metaverse/Metaverse'
import SalientGameFeatures from './SalientGameFeatures/SalientGameFeatures'
import ClanTkn from './ClanTkn/ClanTkn'
import StepGuide from './StepGuide/StepGuide'
import GameFaq from '../Game/gamefaq'
import ConqureMetaverse from './ConquerMetaverse/ConqureMetaverse'
import HomeFaq from '../Home/Faq/Faq'

const Game = () => {
  return (
    <Fragment>
        <GameBanner />
        <Metaverse />
        <ConqureMetaverse />
        <SalientGameFeatures />
        <ClanTkn />
        <StepGuide />
        <HomeFaq />
    </Fragment>
  )
}

export default Game