import React from 'react'
import Styles from './TokenDesclimer.module.scss'

const TokenDesclimer = () => {
    return (
        <div className={Styles.tokenDesclimer}>
            <div className={Styles.content}>
                <h4>DISCLIMER</h4>
                <p>
                    Investing in cryptocurrencies involves significant risk, including the risk of loss. The value of cryptocurrencies can fluctuate widely.<br />
                    Please conduct your own research and consult with a financial advisor before making any investment decisions. WSNPT tokens<br />
                    are subject to market risks and potential regulatory changes. Snapit World is not responsible for any financial losses incurred while<br />
                    investing in WSNPT tokens.
                </p>
            </div>
        </div>
    )
}

export default TokenDesclimer