import React from 'react';
import styles from './token.module.scss';
import tokenImage from '../Assets/Token.png'; // Adjust the path as per your folder structure
import { Link } from 'react-router-dom';

const Token = () => {
  return (
    <section className={styles.tokenSection}>
      <div className={styles.container}>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className={styles.tknimg}>
              <img src={tokenImage} alt="WSNPT Token" width="3176" height="2792" className={`${styles.tokenImage}`} />
            </div>

          </div>
          <div className='col-lg-6 col-md-12'>
            <div className={styles.tokenContent}>
              <h2 className={styles.tokenTitle}>WSNPT TOKEN</h2>
              <p className={styles.tokenText}>
              WSNPT, the native digital asset of Snapit World, serves as the backbone of the metaverse's economy, enabling seamless transactions and fostering community engagement. With its utility extending across various in-game activities and virtual experiences, WSNPT empowers users to participate in the vibrant ecosystem while adding value to their digital assets. As users stake WSNPT within the metaverse, they not only unlock exclusive rewards and bonuses but also contribute to the scarcity and appreciation of the token, reinforcing its significance within Snapit World's virtual economy. In essence, WSNPT represents a digital gateway to a world of immersive experiences, economic opportunities, and community-driven growth within the Snapit World metaverse.
              </p>
              <Link to={'/buy-wsnpt'}>
              <button className={styles.tokenButton}>KNOW MORE</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Token;
