import React from 'react';
import styles from './snap.module.scss';
import salvageimg from '../Assets/snapsalvage.png'; // Adjust the path as per your folder structure
import { Link } from 'react-router-dom';

const Snapsalvage = () => {
    return (
        <section className={styles.tokenSection}>
            <div className={styles.container}>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className={styles.tokenContent}>
                            <h2 className={styles.tokenTitle}>SNAP SALVAGE</h2>
                            <p className={styles.tokenText}>
                            Step into the future of gaming with SNAP SALVAGE, where adrenaline-pumping action meets blockchain innovation. Engage in intense 3D multiplayer FPS battles, all while experiencing the security and transparency of blockchain technology. Dominate the battlefield, earn valuable rewards, and stake your claim in the next generation of gaming. Join the revolution today and experience the thrill of SNAP SALVAGE like never before.
                            </p>
                            <Link to={'/snap-salvage'}>
                            <button className={styles.tokenButton}>KNOW MORE</button>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className={styles.tknimg}>
                            <img src={salvageimg} alt="WSNPT Token" className={`${styles.tokenImage} img-fluid`} />
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default Snapsalvage;
