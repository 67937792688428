let environment = "live";
let config = null;

if (environment === "local") {
    config = {
        FRONTEND_URL: "http://localhost:3000",
        BACKEND_URL: "http://localhost:3001/",
        // BACKEND_URL: "https://api.python50dec.com/",
        ETH_RPC_URL: "https://sepolia.infura.io/v3/0fb00006e6b749c2b39c1f8594b8b243",
        POLYGON_RPC_URL:"https://polygon-mumbai.g.alchemy.com/v2/VsljgkrR1BDkKiOO2__Lce-EfP6UUdjb",
        Eth_chain_ID:11155111,
        Polygon_CHAIN_ID: 80001,
        SYMBOL: "MATIC",
        BLOCKEXPLORER: "https://testnet.bscscan.com/",
        EthUsdtcontract: "0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0",
        polygonUsdtContract: "0x38eC6e6E20C20523Cc3976eFdf95E093fB454B97", //0xA445f1cF40EE47E038AA50ABCE71d9dBED46135b"
        Adminaddress:"0x45E4890d0E151C83E93b1f2BdC7eEC7b104eE9b0",
        ethwrappedsnapit:"0x6d906e526a4e2Ca02097BA9d0caA3c382F52278E",
        Polygonwrappedsnapit:"0x8F0Aaf7444bdB3c93b068D17669231558171Be0B"
    };  
    
} else if (environment === "live") {
    config = {
        ETH_RPC_URL: "https://mainnet.infura.io/v3/0fb00006e6b749c2b39c1f8594b8b243",
        POLYGON_RPC_URL:"https://polygon-rpc.com/",
        Eth_chain_ID:1,
        Polygon_CHAIN_ID: 137,
        BACKEND_URL: "https://api.snapit.world/",
        // BACKEND_URL: "http://localhost:3001/",

        EthUsdtcontract: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        polygonUsdtContract: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        Polygonwrappedsnapit: "0x2D12054Fe9735dA5C85EC994BEb7C8eaD6E5cB00",
        ethwrappedsnapit:"0xF532E8298D9b5021cb762EDe236493BE8Fff30B4",
        Adminaddress:"0xa11a26Ad6C254fF869a1A82a88D88875a1538897",
        Eth_Blockexplorer:"https://etherscan.io",
        POLYGON_Blockexplorer:"https://polygonscan.com"
    };
}

export default config;
